@import url('https://fonts.googleapis.com/css2?family=Epilogue:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Nunito:ital,wght@0,300;0,400;0,500;0,600;0,700;1,600;1,700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Epilogue:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Newsreader:ital,wght@0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&family=Nunito:ital,wght@0,300;0,400;0,500;0,600;0,700;1,600;1,700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Chivo:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&family=Epilogue:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Newsreader:ital,wght@0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&family=Nunito:ital,wght@0,300;0,400;0,500;0,600;0,700;1,600;1,700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


*{padding: 0;margin: 0;box-sizing: border-box;}

header{background-color: transparent;padding: 40px 20px;border-bottom: 2px solid #1DDFFC;position: absolute; left: 0; right: 0; margin: auto; z-index: 999;}
header .container{max-width: 1535px;margin: auto;}
ul{list-style: none;}
img{max-width: 100%;}
a{text-decoration: none;}
.container{max-width: 1320px;margin: auto;}

/*************header_css***************/
.header_main {display: flex;align-items: center;justify-content: space-between;column-gap: 20px;}
.nav_menu_list {display: flex;align-items: center;justify-content: space-between;column-gap: 70px;}
a.nav_link {font-family: 'Epilogue';font-style: normal;font-weight: 400;font-size: 20px;line-height: 22px;color: #FFFFFF;}
.btn {font-family: 'Epilogue';font-style: normal;font-weight: 400;font-size: 20px;line-height: 26px;color: #0A0634;display: flex; align-items: center; justify-content: center;padding: 10px 30px;text-align: center;}
.dark_btn.btn {background: #0A0A3C;border: 2px solid #FFFFFF;box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);border-radius: 25px;color: #fff;padding: 10px 30px;}
.header_button_area {display: flex;column-gap: 30px;}
.lightblue_btn.btn {background: #1DDFFC;box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);border-radius: 25px;border: none;}
.nav_toggle img {filter: invert();width: 30px;height: 30px;cursor: pointer;}
.mobile_menu{display: none;}
.navbar-toggle.header_nav_btn {background: transparent;border: none;}
.navbar-toggle.header_nav_btn:hover .mobile_menu_listing {right: 0;}
.mobile_menu_listing {display: flex;flex-direction: column;align-items: flex-start;row-gap: 30px;position: fixed;right: -100%;top: 0;width: 100%;z-index: 99;background-color: #00032c;padding: 110px 20px 30px;height: 100vh;transition: all .3s ease-in-out;-webkit-transition: all .3s ease-in-out;}
.nav_toggle .mobile_menu_listing.active  {right: 0;}
.mobile_menu_listing .btn {width: 180px;}
.logo_area{position: relative;z-index: 999;}
.hide_menu {background-color: transparent;border: none;}
.hide_menu img {width: 25px;height: 25px;}
.close_btn {position: absolute;right: 20px;top: 40px;}
.mobile_menu_listing::before {content: '';background-color: #1DDFFC;width: 100%;height: 2px;}
.mobile_menu_item.style_2 {display: flex;column-gap: 20px;}
/* ul.mobile_menu_listing {display: none;} */

/********header_end**********/

/******section_banner*****/

section {position: relative;}
.col-left {width: 56%;}
.col-right {width: 40%;}
.banner_number {padding-left: 30%;}
.number_div::before {content: '';background-color: #fd48ff;width: 3px;height: 20px;position: absolute;right: 8px;top: 16px;transform: rotate(148.65deg);z-index: 0;}
.number_div::after {content: '';background-color: #D9D9D9;width: 6px;height: 6px;border-radius: 50px;position: absolute;right: 0px;top: 34px;}
.row {display: flex;flex-wrap: wrap;position: relative; z-index: 2;justify-content: space-between;}
.banner_section {background-color: #010D25;padding: 270px 20px 485px;background-image: url(./assets/images/banner_full_img.png);background-position: bottom;background-size: 100% auto;background-repeat: no-repeat;z-index: 2;}
h1.heading_main {font-family: 'Newsreader';font-style: normal;font-weight: 300;font-size: 80px;line-height: 88px;color: #FFFFFF;}
.main_text {font-family: 'Epilogue';font-style: normal;font-weight: 400;font-size: 28px;line-height: 39px;color: #C5C5C5;}
.transperent_btn.btn {border: 2px solid #F5F5F5;border-radius: 35px;background-color: transparent;color: #fff;}
.banenr_btn {display: flex;column-gap: 35px;align-items: center;padding-top: 40px;}
.banner_inner .main_text {padding: 15px 0;}
.transperent_btn.get_btn.btn {padding: 13.5px 48.2px;}
.transperent_btn.contact_btn.btn {padding: 13.5px 38px;}

.number_div {width: 68px;height: 23px;background: #FD48FF;padding: 2px;display: flex;align-items: center;justify-content: center;position: relative;}
.number_div.style_2 {background-color: #902BE1;margin: 34px 74px;}
.number_div.style_3{background-color: #1DDFFC;margin-left: 18px;}
.number_style {font-family: 'Chivo';font-style: normal;font-weight: 400;font-size: 17px;line-height: 20px;color: #FFFFFF;position: relative;z-index: 2;}
.number_div.style_2::before{background-color: #902BE1;}
.number_div.style_3::before{background-color: #1DDFFC;}
/* .banner_section::before {content: '';background-image: url('./assets/images/shape_svg.svg');width: 100%;height: 100%;position: absolute;bottom: -4px;background-repeat: no-repeat;background-position: bottom;background-size: 100%;left: 0;z-index: 0;} */
/* .banner_section::after {content: '';background-image: url('./assets/images/light_house.png');width: 100%;height: 100%;position: absolute;bottom: -4px;background-repeat: no-repeat;background-position: bottom;background-size: 100%;left: 0;z-index: 0;} */
.banner_light_house img {position: absolute;right: 0;bottom: -1px;width: 91%;}
.banner_section .container {max-width: 1365px;}

.banner_section::after {content: '';background-color: #f9f9f7;width: 100%;height: 5px;position: absolute;bottom: 0;left: 0;}

/**********takeback_section***********/

.takeback_section{background-color: #F9F9F7;padding: 0px 20px 30px; margin-top: -85px;}
.common_heading {font-family: 'Newsreader';font-style: normal;font-weight: 300;font-size: 64px;line-height: 76px;color: #1A2366;}
.takeback_item {font-family: 'Epilogue';font-style: normal;font-weight: 400;font-size: 28px;line-height: 33px;color: #00032C;}
.takeback_item {display: flex;align-items: start;column-gap: 36px;padding: 23px 0;}
.takeback_item > img{margin-top: 3px;}
.take_back_inner {position: relative;z-index: 1;}
.white_btn.btn {font-family: 'Epilogue';font-style: normal;font-weight: 500;font-size: 20px;line-height: 25px;letter-spacing: 0.05em;color: #1A2366;background: #1DDFFC;border-radius: 25px;border: none;}
.takeback_listing {padding: 25px 0 45px;}
.white_btn.btn {max-width: 417px;height: 56px;}
.takeback_section .container {max-width: 1365px;}
.v_align {align-items: center;}

.col-7 {width: 50%;}

.col-5 {width: 40%;}
.take_back_inner_img {padding-top: 60px;}

/************cloud_section**************/

.cloudbased_section {padding: 265px 20px 130px;background-color: #00032C;}
.cloudbased_section .container {position: relative; z-index: 2;}
.common_heading.style_2 {color: #F9F9F7;}
.cloudbased_inner {text-align: center;}
.font_weight_set {font-weight: 400;}
.cloudbased_btn {text-align: center;display: flex;align-items: center;justify-content: center;padding-top: 45px;}
.cloudbased_inner .common_heading {line-height: 83px;}
.cloudbased_section .container {max-width: 1280px;}
.lightblue_btn.cdx_btn.btn {box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);width: 220px;height: 56px;letter-spacing: 0.05em;}
.cloudbased_section::before {content: '';background-image: url('./assets/images/new_dots_img.png');background-repeat: no-repeat;background-size: contain;width: 100%;height: 100%;position: absolute;background-position: bottom;left: 0;bottom: -100px;z-index: 0;}
.cloudbased_section::after {content: '';background-image: url('./assets/images/top_shape.svg');background-size: 100%;width: 100%;height: 30%;position: absolute;top: -40px;left: 0;background-repeat: no-repeat;}


/************known2u_section**************/

.known2u_section {background-color: #F9F9F7;padding: 155px 20px 170px;}
.common_text {font-family: 'Epilogue';font-style: normal;font-weight: 400;font-size: 24px;line-height: 30px;letter-spacing: 0.05em;color: #555662;}
.known2u_section .container{position: relative; z-index: 1;}
.known2u_items {display: flex;column-gap: 10px;padding: 0 25px;align-items: center;}
.heading_h3 {font-family: 'Chivo';font-style: normal;font-weight: 400;font-size: 32px;line-height: 40px;letter-spacing: 0.05em;color: #1A2366;}
.para_new {font-family: 'Epilogue';font-style: normal;font-weight: 400;font-size: 22px;line-height: 27px;letter-spacing: 0.05em;color: #00032C;padding-top: 6px;}
.learn_link {color: #902BE1;text-decoration: underline;}
.Known2U_listing {background: #ffffff;border: 2px solid #1A2366;border-radius: 35px;filter: drop-shadow(4px 4px 25px rgba(148, 148, 148, 0.5));}
.Known2U_img {padding: 40px;display: flex;align-items: center;justify-content: center;}
.Known2U_img {padding: 20px;display: flex;align-items: center;justify-content: center;min-width: 160px;height: 100px;width: 160px;}
.known2u_items {border-bottom: 2px solid #1A2366;}
.known2u_items:last-child {border-bottom: none;}
.Known2U_content {border-left: 2px solid #1A2366;padding: 30px 40px;max-width: 82%;}
.Known2U_table_area {padding-top: 110px;}
.common_text.style_2 {padding: 10px 0 0;}
.known2u_section .common_heading {line-height: 70px;}

/************howitwork_section**************/

.common_text.style_3 {font-family: 'Epilogue';font-style: normal;font-weight: 400;font-size: 24px;line-height: 30px;letter-spacing: 0.05em;color: #F9F9F7;padding: 10px 0 0;}
.heading_h3.style_2 {color: #000000;}
.para_next {font-family: 'Epilogue';font-style: normal;font-weight: 400;font-size: 24px;line-height: 30px;text-align: center;letter-spacing: 0.05em;color: #000000;}
.itwork_items {text-align: center;}
.itwork_items:last-child {border-right: none;}
.itwork_items {width: 33.33%;border-right: 3px solid #1A2366;padding: 72px 20px;height: 409px;display: flex;align-items: center; flex-direction: column;}
.itwork_listing {display: flex;align-items: center;border: 3px solid #1A2366;border-radius: 35px;background: #FFFFFF;}
.img_area {display: flex;align-items: center;justify-content: center;padding:0 0 52px;height: 110px;}
.itwork_box {padding-top: 95px;}
.how_itwork_section {padding: 140px 20px 160px;background-image: url('./assets/images/bh_how_it_work.jpg');background-repeat: no-repeat; background-size: cover;}
.itwork_content .heading_h3.style_2 {padding-bottom: 23px;font-family: 'Epilogue';color: #1A2366;font-weight: 600;}
.itwork_content {padding-top: 10px;}
.how_itwork_section .common_heading.style_2 {color: #F9F9F7;line-height: 70px;}
/* .how_itwork_section::after {content: '';background: linear-gradient(0deg, rgba(26, 35, 102, 0.7) 4.31%, rgba(26, 35, 102, 0) 92.1%);width: 100%;height: 526px;position: absolute;bottom: 0;z-index: 0;left: 0;background-image: url('./assets/images/bg_how_it_work.jpg');} */
.how_itwork_section .container{position: relative; z-index: 2;max-width: 1390px;}
.img_area .box_images {width: 100px;height: 100px;}
.itwork_content .para_next {max-width: 80%;margin: auto;}
.itwork_content .heading_h3.style_2 {padding-bottom: 23px;font-family: 'Epilogue';color: #1A2366;font-weight: 600;}



/******************testimonial_section***************/

.testimonial_section {background-color: #F9F9F7;padding: 95px 20px 125px;}
.testimonial_section .container{position: relative; z-index: 1;}
.slider_area {background: #ffffff;padding: 50px;max-width: 1000px;margin: auto;border: 2px solid #1A2366;box-shadow: 4px 4px 35px rgba(0, 0, 0, 0.15);}
.para_next.style_4 {font-style: italic;}
.testimonial_name {font-family: 'Epilogue';font-style: normal;font-weight: 400;font-size: 24px;line-height: 30px;text-align: center;letter-spacing: 0.05em;color: #902BE1;}
.testimonial_title{font-family: 'Epilogue';font-style: normal;font-weight: 400;font-size: 16px;line-height: 20px;text-align: center;letter-spacing: 0.05em;color: #1A2366;}
.slider_content {padding: 0 100px;margin: auto;}
.testimonial_name {padding-top: 20px;}
.main_slider_area {padding-top: 75px;}
.para_next.style_4 {font-style: italic;color: #1A2366;font-family: 'Newsreader';}
.slider_area{z-index: 1;}
.slider_area::after {content: '';background-image: url('./assets/images/trangle_img.svg'); bottom: -51px;width: 53px;height: 53px;position: absolute;right: 0;left: 0;margin: auto;z-index: -1;background-size: 100% auto;background-repeat: no-repeat;}




/**********************contactus_section*******************/
.contactus_section {padding: 110px 20px 115px;background-image: url('./assets/images/contact_bg_new.png');background-repeat: no-repeat;background-size: cover;background-position: center;background-color: #1A2366;}
.contactus_section .common_heading.style_2 {text-align: center;}
.para_next.style_2 {width: 75%;margin: auto;padding-top: 30px;color: #F9F9F7;}
.form_area input, .form_area textarea {background: #F9F9F7;border: 3px solid #00032C;border-radius: 10px;width: 100%;margin: auto;padding: 20px 20px;margin: 12px 0;font-family: 'Chivo';font-style: normal;font-weight: 400;font-size: 16px;letter-spacing: -0.015em;color: #555662;}
.form_area textarea {padding: 14px 20px;height: 220px;resize: none;}
.form_btn_area {text-align: center;display: flex;align-items: center;justify-content: center;padding-top: 30px;}
.form_area {width: 64%;margin: auto;padding-top: 44px;}
.lightblue_btn.submit_btn.btn {background: #1DDFFC;box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);border-radius: 25px;height: 56px;width: 253px;}
body .slider_area .slick-prev {left: 35px;height: 50px;display: flex;align-items: center;justify-content: center;}
body .slider_area .slick-next {right: 35px;height: 50px;display: flex;align-items: center;justify-content: center;}
.form_area input:focus-visible, .form_area textarea:focus-visible {outline: 0;}
.para_next.style_2 .learn_link{color: #F9F9F7;}
.contactus_section::before {content: '';background-image: url('./assets/images/new_dots_img.png');background-repeat: no-repeat;width: 100%;height: 100%;position: absolute;left: 0;background-size: contain;background-position: bottom; z-index: 0;}
.contactus_section .container{position: relative;z-index: 1;}




/********************footer_section********************/
footer{background-color: #010D25;padding: 140px 20px 30px;position: relative;overflow: hidden;}
footer .container{max-width: 1440px;position: relative;z-index: 1;}
.footer_menu_area {border-bottom: 3px solid #902BE1;display: flex;align-items: baseline;justify-content: space-between;column-gap: 20px;padding-bottom: 40px;}
.footer_menu_list {display: flex;align-items: center;column-gap: 60px;}
.social_media_listing {display: flex;align-items: center;column-gap: 15px;padding-top: 40px;}
.social_media_icon {width: 30px;height: 30px;display: block;}
.copywrite_text {font-family: 'Epilogue';font-style: normal;font-weight: 400;font-size: 16px;line-height: 20px;text-align: center;letter-spacing: -0.015em;color: #FFFFFF;}
.copywrite_area {text-align: center;padding-top: 155px;}
footer::after{content: '';background-image: url('./assets/images/footer_bg.png');background-repeat: no-repeat;background-size: contain;background-position: right;width: 100%;height: 100%;position: absolute;top: 0;z-index: 0;left:0;}
footer::before {content: '';background-image: linear-gradient(90deg, #010D25 24.92%, rgba(1, 13, 37, 0.549991) 47.16%, rgba(1, 13, 37, 0) 54.58%);width: 100%;height: 100%;position: absolute;top: 0;z-index: 1;right: -25%;margin: auto;}